@import url('https://fonts.googleapis.com/css2?family=Bungee+Outline&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Display:wght@900&family=Bungee&family=Bungee+Inline&family=Bungee+Shade&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Hairline&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Hairline&family=Open+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
/*for font picker */
@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre&family=Adamina&family=Alegreya&family=Alegreya+Sans+SC&family=Annie+Use+Your+Telescope&family=Architects+Daughter&family=Arsenal&family=Bad+Script&family=Bellota&family=Bevan&family=Bitter&family=Cardo&family=Caveat&family=Charmonman&family=Cinzel:wght@500&family=Cutive+Mono&family=Dawning+of+a+New+Day&family=Didact+Gothic&family=Flamenco&family=Frank+Ruhl+Libre&family=Fredoka+One&family=Fresca&family=Gentium+Basic&family=Handlee&family=Homemade+Apple&family=IM+Fell+Double+Pica&family=Ibarra+Real+Nova&family=Indie+Flower&family=Kirang+Haerang&family=Londrina+Solid&family=Marcellus+SC&family=Merriweather&family=Montserrat&family=Oswald&family=Playfair+Display:wght@500&family=Pompiere&family=Raleway&family=Sarina&family=Staatliches&family=Stoke&family=Tenor+Sans&family=Ultra&family=Wire+One&family=Yeseva+One&display=swap');
/*subtitle fonts*/
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Open+Sans&family=Raleway&family=Source+Sans+Pro&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
